import headerLogo from "assets/logo.svg";
export const LAYOUT_IMG = {
  headerLogo: headerLogo,
  payment1: require("assets/pay_1.png"),
  payment2: require("assets/pay_2.png"),
  payment3: require("assets/pay_3.png"),
  payment4: require("assets/pay_4.png"),
  aboutBanner: require("assets/about-us.png"),
  contactBanner: require("assets/contact-us.jpg"),
  facebookImg: require("assets/facebook.png"),
  threads: require("assets/threads.png"),
  telegram: require("assets/telegram.png"),
  linkedin: require("assets/linked_in.png"),
  instagram: require("assets/instagram.png"),
  discord: require("assets/discord.png"),
  teamspeak: require("assets/teamspeak.png"),
  termsBanner: require("assets/terms_services.webp"),
  vision: require("assets/vision.png"),
  privacy: require("assets/privacy.png"),
  privacyBanner: require("assets/privacy-banner.webp"),
  customerprivacy: require("assets/privacy.png"),
};
